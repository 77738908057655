import { useContext } from 'react'
import { Footnote } from '@telus-uds/components-web'
import { SiteBuilderContext } from '../../renderer/context/SiteBuilderContext'
import { Language } from 'src/siteBuilder/locale'

type SupProps = {
  legalKey: string | string[]
  copy: Language
  type?: 'indexed' | 'nonIndexed'
}

function Sup({ legalKey, copy = 'en', type = 'indexed' }: SupProps) {
  const context = useContext(SiteBuilderContext)

  if (type !== 'indexed') return <></>
  let legalKeys: string[] = []

  if (!Array.isArray(legalKey)) {
    legalKeys[0] = legalKey
  } else {
    legalKeys = legalKey
  }

  const footNoteLinks = legalKeys.map((key) => {
    const legalKey = context?.dictionary?.legal?.[key]
    if (!legalKey) {
      return false
    }
    return (
      <Footnote.Link
        key={legalKey.index}
        copy={copy}
        number={[legalKey.index + 1]}
        fontSize="14"
        onClick={(number: number, ref) => {
          context.handleFootnoteLinkClick(number, legalKey.value, ref)
        }}
      />
    )
  })

  return <>{footNoteLinks}</>
}

export function withLegalText(content: React.ReactNode, copy: Language = 'en'): React.ReactNode {
  if (Array.isArray(content)) return content.map((c: React.ReactNode) => withLegalText(c, copy))

  if (typeof content !== 'string') return content

  let indexedKeys: string[] = []
  const c = content
    .replace(/\${LEGAL./g, '}legalKey:')
    .replace(/\${NONINDEX_LEGAL./g, '}nonIndexedLegalKey:')
    .replace('} {', '}{')
    .split('}')
    .filter((w) => w.trim() !== '')
    .reduce((acc: React.ReactNode[], curr, index, array) => {
      if (curr.startsWith('legalKey:')) {
        indexedKeys.push(curr.replace('legalKey:', ''))
      } else if (curr.startsWith('nonIndexedLegalKey:')) {
        acc.push(<Sup copy={copy} type="nonIndexed" key={curr} legalKey={curr.replace('nonIndexedLegalKey:', '')} />)
      } else {
        /*
         * if there are indexedKeys while reaching some other text, add the indexedKeys to acc
         * and reset the array
         */
        if (indexedKeys.length > 0) {
          acc.push(<Sup copy={copy} key={curr} legalKey={indexedKeys} />)
          indexedKeys = []
        }
        acc.push(curr)
      }
      // if on the last item in the content and there are leftover indexedKeys, add them to acc
      if (indexedKeys.length > 0 && index === array.length - 1) {
        acc.push(<Sup copy={copy} key={curr} legalKey={indexedKeys} />)
      }
      return acc
    }, [])
  return c
}
