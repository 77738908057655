import { HeadingTag } from '@telus-uds/components-web/types/common'
import Palette from '@telus-uds/palette-allium/build/web/palette'

export type VariantColor =
  | 'default'
  | 'brand'
  | 'dark'
  | 'light'
  | 'positive'
  | 'warning'
  | 'danger'
  | 'black'
  | 'white'
  | 'brand2'
  | 'brand3'
  | 'alternative1'
  | 'alternative2'
  | 'alternative3'

export type VariantSize = 'micro' | 'small' | 'large' | 'eyebrow' | 'display1' | 'display2' | HeadingTag

export type IconColor =
  | 'purpleTelus'
  | 'greyCharcoal'
  | 'greyShuttle'
  | 'redDark'
  | 'amberDark'
  | 'greenAccessible'
  | 'white'

export type IconSize = 'small' | 'medium' | 'large' | 'extra large'
export type ListSize = 'small' | 'medium' | 'large'

export const iconColors = {
  purpleTelus: Palette.color.purpleTelus,
  greyCharcoal: Palette.color.greyCharcoal,
  greyShuttle: Palette.color.greyShuttle,
  redDark: Palette.color.redDark,
  amberDark: Palette.color.amberDark,
  greenAccessible: Palette.color.greenAccessible,
  white: Palette.color.white,
  undefined: Palette.color.purpleTelus,
}
