import { List, OrderedList, Span } from '@telus-uds/components-web'
import { useMemo } from 'react'
import { ListSize, iconColors } from '../RichText/TypographyVariantTypes'
import { IListItem } from '@/contentful-types'
import { getIcon } from '@/siteBuilder/utils/getIcon'
import { LegalTextRenderer } from 'src/siteBuilder/utils/text/TextRenderer'
import { withLegalText } from '../../../utils/text/withLegalText'

type ListItemProps = {
  ListComponent: typeof List | typeof OrderedList
  item: IListItem
  iconSize: number
  textSize: ListSize
}

const ListItem = ({ ListComponent, item, textSize, iconSize, ...rest }: ListItemProps) => {
  const IconComponent = useMemo(
    () => (item.fields?.iconName ? getIcon(item.fields?.iconName) : undefined),
    [item.fields?.iconName]
  )

  const iconProps = !!IconComponent
    ? {
        icon: IconComponent,
        iconColor: iconColors[item.fields?.iconColor],
        iconSize: iconSize,
      }
    : null

  const iconMarginTop = textSize === 'large' ? '4px' : ''

  return (
    <ListComponent.Item
      {...rest}
      title={withLegalText(item.fields?.heading)}
      {...iconProps}
      tokens={{ iconMarginTop: iconMarginTop }}
    >
      <Span variant={{ size: textSize }}>
        <LegalTextRenderer>{item.fields?.text}</LegalTextRenderer>
      </Span>
    </ListComponent.Item>
  )
}

export default ListItem
