import React from 'react'
import { A11yText } from '@telus-uds/components-web'

// checks if string contains [[ and ]]
const hasA11yContent = (text: string) =>
  text?.includes('[[') && text?.includes(']]') && text?.indexOf('[[') < text?.indexOf(']]')

// Splits text with accessible content into individual chunks.
// For example getSplitTextContent("Get [[IPhone]] Now") will return ["Get", "[[IPhone]]", "Now"]
const getSplitTextContent = (text: string) =>
  text
    .split(/\[\[|\]\]/g)
    .filter((string) => string)
    .map((string) => string.trim())

// Returns the substrings that are wrapped in [[ ]] tags
const getA11yText = (text: string) =>
  text.match(/\[\[(.*?)\]\]/g).map((string) => string.substring(2, string.length - 2).trim())

const punctuationRegEx = /^[!',-.:;?]/

/**
 * A function that transforms a text that contains ""[[" and "]]" into JSX with `<A11yText />`.
 * @param {string} text is the text that is meant to be rendered with `<A11yText />`.
 * @return {JSX} list of `<spans/>`'s or  `<A11yText />` to be rendered.
 */

export const withAccessibleText = (text: string): React.ReactNode => {
  if (!hasA11yContent(text)) return text

  const allTextContent = getSplitTextContent(text)
  const a11yText = getA11yText(text)

  const copy = allTextContent.map((el, i) => {
    const isLast = i === allTextContent.length - 1
    const nextElementFirstLetterIsPunctuation = !isLast && punctuationRegEx.test(allTextContent[i + 1][0])

    // do not render space if the first character of the next el is a punctuation mark or it's the last item.
    if (a11yText.includes(el) && (nextElementFirstLetterIsPunctuation || isLast)) {
      return <A11yText key={el} text={el} />
    }
    if (a11yText.includes(el)) {
      return (
        <React.Fragment key={i}>
          {i !== 0 && <span data-testid="span-with-space">&nbsp;</span>}
          <A11yText key={i} text={el} />
        </React.Fragment>
      )
    }

    return el
  })

  return copy
}
