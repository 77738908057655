import React, { useContext } from 'react'

import { withLegalText } from './withLegalText'
import { withAccessibleText } from './withAccessibleText'
import { SiteBuilderContext } from '../../renderer/context/SiteBuilderContext'
import { Language } from 'src/siteBuilder/locale'

type textRendererOptions = {
  legal?: boolean
  a11y?: boolean
}
type TextRendererProps = textRendererOptions & {
  children: string
}

const DEFAULT_OPTIONS: textRendererOptions = {
  legal: false,
  a11y: false,
}

const applyOptions = (t: string, lang: Language, opts: textRendererOptions) => {
  if (!t) return null

  let text: React.ReactNode = t
  const options = Object.assign({}, DEFAULT_OPTIONS, opts)
  const { a11y, legal } = options

  if (a11y) {
    text = withAccessibleText(t)
  }
  if (legal) {
    text = withLegalText(text, lang)
  }

  return text
}

// Both Legal + A11y
export const textRenderer = (
  children: string,
  lang: Language,
  options: textRendererOptions = { a11y: true, legal: true }
) => {
  return applyOptions(children, lang, options)
}

export const TextRenderer = ({ children, a11y = true, legal = true }: TextRendererProps) => {
  const { locale } = useContext(SiteBuilderContext)
  return applyOptions(children, locale.language, { a11y, legal }) as JSX.Element
}

// A11y
export const a11yTextRenderer = (children: string, lang: Language) => {
  return applyOptions(children, lang, { a11y: true })
}

export const A11yTextRenderer = ({ children }: TextRendererProps) => {
  const { locale } = useContext(SiteBuilderContext)
  return applyOptions(children, locale.language, { a11y: true }) as JSX.Element
}

// Legal
export const legalTextRenderer = (children: string, lang: Language) => {
  return applyOptions(children, lang, { legal: true })
}

export const LegalTextRenderer = ({ children }: TextRendererProps) => {
  const { locale } = useContext(SiteBuilderContext)
  return applyOptions(children, locale.language, { legal: true }) as JSX.Element
}
