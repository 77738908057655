import { List as AlliumList, OrderedList } from '@telus-uds/components-web'
import { IconColor, IconSize, ListSize } from '../RichText/TypographyVariantTypes'
import ListItem from './ListItem'
import { IListItem } from '@/contentful-types'

export type listItemProps = {
  heading?: string
  text?: string
  iconName?: string
  iconColor?: IconColor
}

export type listProps = {
  items: IListItem[]
  type?: 'ordered' | 'unordered'
  showDivider?: boolean
  textSize?: ListSize
  textStyle?: 'default' | 'compact'
  iconSize?: IconSize
}

export const getIconSize = (size: IconSize) => {
  switch (size) {
    case 'small':
      return 16
    case 'medium':
      return 20
    case 'large':
      return 24
    case 'extra large':
      return 32
    default:
      return 16
  }
}

const List = ({ items, showDivider = false, textSize, type = 'unordered', textStyle, iconSize }: listProps) => {
  const ListComponent = { ordered: OrderedList, unordered: AlliumList }[type]

  const iconSizeNum = getIconSize(iconSize)

  return (
    <ListComponent
      showDivider={showDivider}
      variant={{ size: textSize, compact: textStyle === 'compact' }}
      testID="list-testid"
    >
      {items?.map((item, index) => (
        // TODO: would it be good idea to render item with Block renderer
        <ListItem ListComponent={ListComponent} textSize={textSize} iconSize={iconSizeNum} item={item} key={index} />
      ))}
    </ListComponent>
  )
}

export default List
